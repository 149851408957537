import {gql} from '@apollo/client'

import {RecipeInstructions_recipe} from './types'

interface RecipeInstructionsProps {
  recipe: RecipeInstructions_recipe
}

export default function RecipeInstructions(props: RecipeInstructionsProps): JSX.Element {
  const {recipe} = props
  return <p>Serve in a {recipe.vessel.name}.</p>
}

RecipeInstructions.fragments = {
  recipe: gql`
    fragment RecipeInstructions_recipe on Recipe {
      vessel {
        name
      }
    }
  `,
}
