import {format} from 'd3-format'
import {gql} from '@apollo/client'

import {RecipeVolume_recipe} from './types'
import normalizeQuantity from './utils/normalizeQuantity'

const formatVolume = format('.1f')
const formatPercentage = format('.0%')

function sumBy<T>(arr: T[], iteratee: (item: T) => number): number {
  return arr.reduce((acc, item) => acc + iteratee(item), 0)
}

/** Gets the total volume of ingredients in a recipe, in fluid ounces. */
function getVolumeOfRecipe(recipe: RecipeVolume_recipe): number {
  return sumBy(recipe.components, (component) =>
    normalizeQuantity(component.number, component.unit?.flOz)
  )
}

/** Gets the total volume of alcohol in a recipe, in fluid ounces. */
function getAlcoholVolumeOfRecipe(recipe: RecipeVolume_recipe): number {
  return sumBy(recipe.components, (component) => {
    const volume = normalizeQuantity(component.number, component.unit?.flOz)
    return volume * (component.ingredient.abv / 100)
  })
}

interface RecipeVolumeProps {
  recipe: RecipeVolume_recipe
}

export default function RecipeVolume(props: RecipeVolumeProps): JSX.Element {
  const {recipe} = props
  const volume = getVolumeOfRecipe(recipe)
  const alcoholVolume = getAlcoholVolumeOfRecipe(recipe)
  return (
    <p>
      {formatVolume(volume)} oz. {formatPercentage(alcoholVolume / volume)} ABV.
    </p>
  )
}

RecipeVolume.fragments = {
  recipe: gql`
    fragment RecipeVolume_recipe on Recipe {
      components {
        number
        unit {
          flOz
        }
        ingredient {
          abv
        }
      }
    }
  `,
}
