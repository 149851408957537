import styled from '@emotion/styled'
import {gql} from '@apollo/client'

import {RecipeSource_recipe} from './types'
import BookLink from './links/BookLink'

const Paragraph = styled.p`
  opacity: 0.5;
  font-style: italic;
`

interface RecipeSourceProps {
  recipe: RecipeSource_recipe
}

export default function RecipeSource(props: RecipeSourceProps): JSX.Element | null {
  const {recipe} = props
  return (
    <Paragraph>
      As written in <BookLink id={recipe.book.id}>{recipe.book.title}</BookLink> (page {recipe.page}
      ):
    </Paragraph>
  )
}

RecipeSource.fragments = {
  recipe: gql`
    fragment RecipeSource_recipe on Recipe {
      page
      book {
        id
        title
      }
    }
  `,
}
