import {buildCocktailPath} from '../paths'

import TextLink from './TextLink'

interface CocktailLinkProps {
  children: React.ReactNode
  id: string
}

export default function CocktailLink(props: CocktailLinkProps): JSX.Element {
  const {children, id} = props
  return <TextLink to={buildCocktailPath(id)}>{children}</TextLink>
}
