import {gql} from '@apollo/client'

import {IngredientCard_ingredient} from './types'
import CocktailLink from './links/CocktailLink'
// import IngredientLink from './links/IngredientLink'
import IngredientInventorySwitch from './IngredientInventorySwitch'
import Container from './components/Container'

interface IngredientCardProps {
  ingredient: IngredientCard_ingredient
}

export default function IngredientCard(props: IngredientCardProps): JSX.Element {
  const {ingredient} = props
  const cocktails = ingredient.recipes.map((recipe) => recipe.cocktail)
  return (
    <Container>
      <h1>{ingredient.name}</h1>
      <IngredientInventorySwitch />
      {/* {types.length > 0 && (
        <div>
          <h2>Types</h2>
          <ul>
            {types.map(type => (
              <li key={type.id}>
                <IngredientLink id={type.id}>{type.name}</IngredientLink>
              </li>
            ))}
          </ul>
        </div>
      )} */}
      {cocktails.length > 0 && (
        <div>
          Used in:
          <ul>
            {cocktails.map((cocktail) => (
              <li key={cocktail.id}>
                <CocktailLink id={cocktail.id}>{cocktail.name}</CocktailLink>
              </li>
            ))}
          </ul>
        </div>
      )}
    </Container>
  )
}

IngredientCard.fragments = {
  ingredient: gql`
    fragment IngredientCard_ingredient on Ingredient {
      name
      recipes {
        cocktail {
          id
          name
        }
      }
    }
  `,
}
