import styled from '@emotion/styled'

export default styled.h1`
  margin: 1em 0;
  font-weight: 400;
  font-size: 42px;
  :first-child {
    margin-top: 0;
  }
`
