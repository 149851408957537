import styled from '@emotion/styled'
import {gql, useQuery} from '@apollo/client'

import {CocktailQuery, CocktailQueryVariables} from './types'
import Recipe from './Recipe'
// import CocktailCard from './CocktailCard'
// import CocktailLink from './links/CocktailLink'
import Header from './components/Header'
// import Section from './components/Section'

const COCKTAIL_QUERY = gql`
  query CocktailQuery($id: ID!) {
    cocktail(id: $id) {
      name
      recipes {
        id
        ...Recipe_recipe
      }
    }
  }
  ${Recipe.fragments.recipe}
`

const Container = styled.div`
  padding: 64px;
  font-family: var(--font-family-serif);
  font-size: 16px;
`

interface CocktailProps {
  cocktailId: string
}

export default function Cocktail(props: CocktailProps): JSX.Element | null {
  const {cocktailId} = props
  const {data, loading} = useQuery<CocktailQuery, CocktailQueryVariables>(COCKTAIL_QUERY, {
    variables: {id: cocktailId},
  })
  if (loading || !data?.cocktail) return null
  return (
    <Container>
      <Header>{data.cocktail.name}</Header>
      {/* {cocktail.variantOf && (
        <p>
          Variant of{' '}
          <CocktailLink id={cocktail.variantOf.id}>{cocktail.variantOf.name}</CocktailLink>.
        </p>
      )} */}
      {data.cocktail.recipes.map((recipe) => (
        <div key={recipe.id}>
          <Recipe recipe={recipe} />
        </div>
      ))}
      {/* {cocktail.variants.length > 0 && (
        <Section title="Variants">
          {cocktail.variants.map(variant => (
            <CocktailCard key={variant.id} cocktail={variant} />
          ))}
        </Section>
      )} */}
    </Container>
  )
}
