import {gql} from '@apollo/client'

import {RecipeComponent_component} from './types'
import IngredientLink from './links/IngredientLink'
import formatQuantity from './utils/formatQuantity'

interface RecipeComponentProps {
  component: RecipeComponent_component
}

export default function RecipeComponent(props: RecipeComponentProps): JSX.Element | null {
  const {component} = props
  // TODO: ingredient should be plural if quantity is >1 unitless
  return (
    <>
      {formatQuantity(component.number, component.unit?.name)}{' '}
      <IngredientLink id={component.ingredient.id}>{component.ingredient.name}</IngredientLink>
    </>
  )
}

RecipeComponent.fragments = {
  component: gql`
    fragment RecipeComponent_component on RecipeComponent {
      number
      unit {
        name
        abbreviation
      }
      ingredient {
        id
        name
      }
    }
  `,
}
