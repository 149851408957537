import {RouteComponentProps} from 'react-router-dom'
import styled from '@emotion/styled'
import {gql, useQuery} from '@apollo/client'

import Cocktail from '../Cocktail'
import CocktailsExplorer from '../CocktailsExplorer'
import {PageCocktailsQuery} from '../types'

const PAGE_COCKTAILS_QUERY = gql`
  query PageCocktailsQuery {
    cocktails {
      ...CocktailsExplorer_cocktail
    }
  }
  ${CocktailsExplorer.fragments.cocktail}
`

const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
`

const CocktailPanel = styled.div`
  background: white;
  border: 1px solid #e7e9f1;
  overflow-y: scroll;
`

type PageCocktailProps = RouteComponentProps<{cocktail?: string}>

export default function PageCocktails(props: PageCocktailProps): JSX.Element | null {
  const {match} = props
  const {data, loading} = useQuery<PageCocktailsQuery>(PAGE_COCKTAILS_QUERY)
  if (loading || !data?.cocktails) return null

  return (
    <Container>
      <CocktailsExplorer cocktails={data.cocktails} />
      <CocktailPanel>
        {match.params.cocktail && <Cocktail cocktailId={match.params.cocktail} />}
      </CocktailPanel>
    </Container>
  )
}
