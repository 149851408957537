import {StrictMode} from 'react'
import {BrowserRouter} from 'react-router-dom'
import styled from '@emotion/styled'

import AppApolloProvider from './AppApolloProvider'
import AppHeader from './AppHeader'
import AppRouter from './AppRouter'
import AppGlobalStyles from './AppGlobalStyles'
import ErrorBoundary from './ErrorBountary'

const Main = styled.main`
  margin: 16px auto;
  max-width: 980px;
`

export default function App(): JSX.Element {
  return (
    <StrictMode>
      <BrowserRouter>
        <AppApolloProvider>
          <ErrorBoundary>
            <AppGlobalStyles />
            <AppHeader />
            <Main>
              <AppRouter />
            </Main>
          </ErrorBoundary>
        </AppApolloProvider>
      </BrowserRouter>
    </StrictMode>
  )
}
