import {RouteComponentProps} from 'react-router-dom'

import Menu from '../menu/Menu'

type PageMenuProps = RouteComponentProps<{menu: string; mode?: string}>

export default function PageMenu(props: PageMenuProps): JSX.Element | null {
  const {match} = props
  const mode = match.params.mode === 'spec' ? 'spec' : 'display'
  return <Menu id={match.params.menu} mode={mode} />
}
