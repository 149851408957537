export function buildBookPath(id: string): string {
  return `/sources/${id}`
}

export function buildCocktailPath(id: string): string {
  return `/cocktails/${id}`
}

export function buildMenuPath(id: string, mode: 'display' | 'spec' = 'display'): string {
  return mode === 'display' ? `/menus/${id}` : `/menus/${id}/${mode}`
}

export function buildRecipePath(id: string): string {
  return `/recipes/${id}`
}

export function buildIngredientPath(id: string): string {
  return `/ingredients/${id}`
}
