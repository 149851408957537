import {RouteComponentProps} from 'react-router-dom'
import {gql, useQuery} from '@apollo/client'

import {SourceQuery, SourceQueryVariables} from '../types'
import BookCard from '../BookCard'

const SOURCE_QUERY = gql`
  query SourceQuery($id: ID!) {
    book(id: $id) {
      ...BookCard_book
    }
  }
  ${BookCard.fragments.book}
`

type PageSourceProps = RouteComponentProps<{source: string}>

export default function PageSource(props: PageSourceProps): JSX.Element | null {
  const {match} = props
  const {data, loading} = useQuery<SourceQuery, SourceQueryVariables>(SOURCE_QUERY, {
    variables: {id: match.params.source},
  })
  if (loading || !data?.book) return null
  return <BookCard book={data.book} />
}
