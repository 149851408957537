import styled from '@emotion/styled'
import {gql} from '@apollo/client'

import {RecipeComponents_component} from './types'
import RecipeComponent from './RecipeComponent'

const List = styled.ul`
  margin: 1em 0;
`

const Item = styled.li`
  margin: 6px 0;
`

interface RecipeComponentsProps {
  components: RecipeComponents_component[]
}

export default function RecipeComponents(props: RecipeComponentsProps): JSX.Element {
  const {components} = props
  return (
    <List>
      {components.map((component) => (
        <Item key={component.ingredient.id}>
          <RecipeComponent component={component} />
        </Item>
      ))}
    </List>
  )
}

RecipeComponents.fragments = {
  component: gql`
    fragment RecipeComponents_component on RecipeComponent {
      ingredient {
        id
      }
      ...RecipeComponent_component
    }
    ${RecipeComponent.fragments.component}
  `,
}
