import styled from '@emotion/styled'
import {gql} from '@apollo/client'

import {IngredientsTable_ingredient, IngredientsTableRow_ingredient} from './types'
import IngredientLink from './links/IngredientLink'
import IngredientInventorySwitch from './IngredientInventorySwitch'

const Table = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  thead {
    text-align: left;
  }
  th,
  td {
    padding: 8px 16px;
  }
`

interface IngredientsTableRowProps {
  ingredient: IngredientsTableRow_ingredient
}

function IngredientsTableRow(props: IngredientsTableRowProps): JSX.Element {
  const {ingredient} = props
  return (
    <tr key={ingredient.id}>
      <td>
        <IngredientLink id={ingredient.id}>{ingredient.name}</IngredientLink>
      </td>
      <td>
        <IngredientInventorySwitch />
      </td>
    </tr>
  )
}

IngredientsTableRow.fragments = {
  ingredient: gql`
    fragment IngredientsTableRow_ingredient on Ingredient {
      id
      name
    }
  `,
}

interface IngredientsTableProps {
  ingredients: IngredientsTable_ingredient[]
}

export default function IngredientsTable(props: IngredientsTableProps): JSX.Element {
  const {ingredients} = props
  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Have</th>
        </tr>
      </thead>
      <tbody>
        {ingredients.map((ingredient) => (
          <IngredientsTableRow key={ingredient.id} ingredient={ingredient} />
        ))}
      </tbody>
    </Table>
  )
}

IngredientsTable.fragments = {
  ingredient: gql`
    fragment IngredientsTable_ingredient on Ingredient {
      id
      ...IngredientsTableRow_ingredient
    }
    ${IngredientsTableRow.fragments.ingredient}
  `,
}
