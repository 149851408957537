import {gql, useQuery} from '@apollo/client'

import {SourcesQuery} from '../types'
import Spinner from '../components/Spinner'

const SOURCES_QUERY = gql`
  query SourcesQuery {
    books {
      title
      subtitle
    }
  }
`

export default function PageSources(): JSX.Element {
  const {data, loading} = useQuery<SourcesQuery>(SOURCES_QUERY)
  if (loading || !data) return <Spinner />
  return (
    <div>
      {data.books.map((book) => (
        <div key={book.title}>
          {book.title}: {book.subtitle}
        </div>
      ))}
    </div>
  )
}
