import {gql, useQuery} from '@apollo/client'

import {MenuQuery, MenuQueryVariables} from '../types'
import Spinner from '../components/Spinner'

import MenuRecipeDisplay from './MenuRecipeDisplay'
import MenuRecipeSpec from './MenuRecipeSpec'

const MENU_QUERY = gql`
  query MenuQuery($id: ID!) {
    menu(id: $id) {
      title
      recipes {
        ...MenuRecipeDisplay_recipe
        ...MenuRecipeSpec_recipe
      }
    }
  }
  ${MenuRecipeDisplay.fragments.recipe}
  ${MenuRecipeSpec.fragments.recipe}
`

interface MenuProps {
  id: string
  mode: 'display' | 'spec'
}

export default function Menu(props: MenuProps): JSX.Element | null {
  const {id, mode} = props
  const {data, loading} = useQuery<MenuQuery, MenuQueryVariables>(MENU_QUERY, {variables: {id}})

  if (loading || !data) return <Spinner />

  const {menu} = data
  if (!menu) return null

  return (
    <article>
      <h2>{menu.title}</h2>
      <section>
        {menu.recipes.map((recipe) =>
          mode === 'display' ? (
            <MenuRecipeDisplay recipe={recipe} key={id} />
          ) : (
            <MenuRecipeSpec recipe={recipe} key={id} />
          )
        )}
      </section>
    </article>
  )
}
