import {gql} from '@apollo/client'

import TextLink from '../links/TextLink'
import {MenuRecipeSpec_recipe} from '../types'
import {buildRecipePath} from '../paths'

interface MenuRecipeSpecProps {
  recipe: MenuRecipeSpec_recipe
}

export default function MenuRecipeSpec(props: MenuRecipeSpecProps): JSX.Element {
  const {recipe} = props
  return (
    <div>
      <TextLink to={buildRecipePath(recipe.id)}>{recipe.cocktail.name}</TextLink>
    </div>
  )
}

MenuRecipeSpec.fragments = {
  recipe: gql`
    fragment MenuRecipeSpec_recipe on Recipe {
      id
      cocktail {
        name
      }
    }
  `,
}
