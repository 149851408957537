import styled from '@emotion/styled'
import {gql, useQuery} from '@apollo/client'

import {PageIngredientsQuery} from '../types'
import IngredientsTable from '../IngredientsTable'

const PAGE_INGREDIENTS_QUERY = gql`
  query PageIngredientsQuery {
    ingredients {
      ...IngredientsTable_ingredient
    }
  }
  ${IngredientsTable.fragments.ingredient}
`

const Container = styled.div`
  height: 100%;
  overflow-y: scroll;
`

export default function PageIngredients(): JSX.Element | null {
  const {data, loading} = useQuery<PageIngredientsQuery>(PAGE_INGREDIENTS_QUERY)
  if (loading || !data?.ingredients) return null
  return (
    <Container>
      <IngredientsTable ingredients={data.ingredients} />
    </Container>
  )
}
