import {gql} from '@apollo/client'

import {MenuRecipeDisplay_recipe} from '../types'

interface MenuRecipeDisplayProps {
  recipe: MenuRecipeDisplay_recipe
}

export default function MenuRecipeDisplay(props: MenuRecipeDisplayProps): JSX.Element {
  const {recipe} = props
  return <div>{recipe.cocktail.name}</div>
}

MenuRecipeDisplay.fragments = {
  recipe: gql`
    fragment MenuRecipeDisplay_recipe on Recipe {
      cocktail {
        name
      }
    }
  `,
}
