import styled from '@emotion/styled'
import {Link} from 'react-router-dom'

export default styled(Link)`
  color: inherit;
  text-decoration: underline hsl(240, 10%, 88%);
  transition: text-decoration 120ms;
  :hover {
    text-decoration: underline currentColor;
  }
`
