import {Component} from 'react'
import {captureException} from '@sentry/browser'

interface ErrorBoundaryProps {
  children: React.ReactNode
}

interface ErrorBoundaryState {
  errorMessage: string | null
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public static getDerivedStateFromError(error: Error): Partial<ErrorBoundaryState> {
    return {errorMessage: error.message}
  }

  public state: ErrorBoundaryState = {
    errorMessage: null,
  }

  public componentDidCatch(error: Error, info: React.ErrorInfo): void {
    const extra = {componentStack: info.componentStack}
    captureException(error, {extra})
  }

  public render(): React.ReactNode {
    const {children} = this.props
    const {errorMessage} = this.state
    if (errorMessage === null) return children
    return <div>{errorMessage}</div>
  }
}
