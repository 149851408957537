import {buildIngredientPath} from '../paths'

import TextLink from './TextLink'

interface IngredientLinkProps {
  children: React.ReactNode
  id: string
}

export default function IngredientLink(props: IngredientLinkProps): JSX.Element {
  const {children, id} = props
  return <TextLink to={buildIngredientPath(id)}>{children}</TextLink>
}
