const MAX_DENOMINATOR = 12
const TOLERANCE = 0.0001

/** Finds a likely denominator for a decimal number. */
function findReasonableDenominator(number: number): number {
  for (let i = 1; i <= MAX_DENOMINATOR; i += 1) {
    if ((i * number) % 1 < TOLERANCE) return i
  }
  return 1
}

const vulgars: Record<string, string | undefined> = {
  '1/2': '½',
  '1/3': '⅓',
  '2/3': '⅔',
  '1/4': '¼',
  '3/4': '¾',
  '1/5': '⅕',
  '2/5': '⅖',
  '3/5': '⅗',
  '4/5': '⅘',
  '1/6': '⅙',
  '5/6': '⅚',
  '1/7': '⅐',
  '1/8': '⅛',
  '3/8': '⅜',
  '5/8': '⅝',
  '7/8': '⅞',
  '1/9': '⅑',
  '1/10': '⅒',
}

const superscripts = '⁰¹²³⁴⁵⁶⁷⁸⁹'
const subscripts = '₀₁₂₃₄₅₆₇₈₉'
const slash = '⁄'

/** Replaces the digits in a number with a substitution list. */
function replaceDigits(number: number, substitutions: string): string {
  return String(number)
    .split('')
    .map((n) => substitutions[parseInt(n, 10)] ?? n)
    .join('')
}

/** Formats a fraction as a unicode string. */
function formatFraction(numerator: number, denominator: number): string {
  const vulgar = vulgars[`${numerator}/${denominator}`]
  if (vulgar) return vulgar
  const unicodeNumerator = replaceDigits(numerator, superscripts)
  const unicodeDecimal = replaceDigits(denominator, subscripts)
  return [unicodeNumerator, slash, unicodeDecimal].join('')
}

/** Formats a number as a human-readable mixed fraction. */
export default function formatNumber(number: number): string {
  const denominator = findReasonableDenominator(number)
  if (denominator === 1) return String(number)
  const numerator = Math.round(denominator * number)
  const remainder = numerator % denominator
  const quotient = Math.trunc(numerator / denominator)
  const fraction = formatFraction(remainder, denominator)
  return quotient ? `${quotient} ${fraction}` : fraction
}
