import {useState} from 'react'

export default function IngredientInventorySwitch(): JSX.Element {
  const [hasIngredient, setHasIngredient] = useState(false)
  return (
    <input
      type="checkbox"
      checked={hasIngredient}
      onChange={(event) => setHasIngredient(event.target.checked)}
    />
  )
}
