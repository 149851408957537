import formatNumber from './formatNumber'
import formatUnit from './formatUnit'

/** Formats a quantity in human-readable recipe notation. */
export default function formatQuantity(number: number, unit?: string): string {
  const formattedNumber = formatNumber(number)
  if (!unit) return formattedNumber
  const formattedUnit = formatUnit(unit, number > 1)
  return `${formattedNumber} ${formattedUnit}`
}
