import styled from '@emotion/styled'
import {gql} from '@apollo/client'

import {BookCard_book} from './types'
import BookLink from './links/BookLink'

const Card = styled.div`
  max-width: 260px;
  border-radius: 2px;
  background: white;
  padding: 32px;
  box-shadow: inset 0 0 0 1px #e7e9f1;
`

const Title = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 1.2em;
`

const Subtitle = styled.h2`
  font-size: 14px;
  font-weight: 500;
`

interface BookCardProps {
  book: BookCard_book
}

export default function BookCard(props: BookCardProps): JSX.Element {
  const {book} = props
  return (
    <Card style={{borderTop: `16px solid ${book.color}`}}>
      <Title>
        <BookLink id={book.id}>{book.title}</BookLink>
      </Title>
      {book.subtitle != null && <Subtitle>{book.subtitle}</Subtitle>}
    </Card>
  )
}

BookCard.fragments = {
  book: gql`
    fragment BookCard_book on Book {
      id
      color
      title
      subtitle
    }
  `,
}
