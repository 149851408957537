import {buildBookPath} from '../paths'

import TextLink from './TextLink'

interface BookLinkProps {
  children: React.ReactNode
  id: string
}

export default function BookLink(props: BookLinkProps): JSX.Element {
  const {children, id} = props
  return <TextLink to={buildBookPath(id)}>{children}</TextLink>
}
