import styled from '@emotion/styled'
import {NavLink} from 'react-router-dom'

const Header = styled.header`
  background: white;
  border-bottom: 1px solid #e7e9f1;
  position: sticky;
  top: 0;
  z-index: 1;
`

const HeaderInner = styled.nav`
  display: flex;
  align-items: center;
  height: 60px;
  margin: 0 auto;
  max-width: 980px;
  padding: 8px 0;
`

const Logo = styled.h1`
  margin: 0;
  font-size: 14px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.28em;
`

const Links = styled.div`
  display: flex;
  padding: 0 16px;

  a {
    display: block;
    font-size: 14px;
    font-weight: 500;
    padding: 0 16px;
    transition: all ease-out 200ms;
  }
  .active {
    color: #3558c7;
  }
`

export default function AppHeader(): JSX.Element {
  return (
    <Header>
      <HeaderInner>
        <NavLink exact to="/">
          <Logo>Mixcreant</Logo>
        </NavLink>
        <Links>
          <NavLink to="/cocktails">Cocktails</NavLink>
          <NavLink to="/ingredients">Ingredients</NavLink>
          <NavLink to="/sources">Sources</NavLink>
        </Links>
      </HeaderInner>
    </Header>
  )
}
