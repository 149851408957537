import styled from '@emotion/styled'
import {gql} from '@apollo/client'

import {CocktailsExplorer_cocktail} from './types'
import CocktailsResults from './CocktailsResults'
import CocktailSearch from './CocktailSearch'

const Container = styled.div`
  display: grid;
  overflow: hidden;
`

interface CocktailsExplorerProps {
  cocktails: CocktailsExplorer_cocktail[]
}

export default function CocktailsExplorer(props: CocktailsExplorerProps): JSX.Element {
  const {cocktails} = props
  return (
    <Container>
      <CocktailSearch />
      <CocktailsResults cocktails={cocktails} />
    </Container>
  )
}

CocktailsExplorer.fragments = {
  cocktail: gql`
    fragment CocktailsExplorer_cocktail on Cocktail {
      ...CocktailsResults_cocktail
    }
    ${CocktailsResults.fragments.cocktail}
  `,
}
