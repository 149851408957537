import {useState} from 'react'
import styled from '@emotion/styled'

const SearchBar = styled.input`
  display: block;
  height: 64px;
  padding: 0 16px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #e7e9f1;
  background: none;
  font-size: 14px;
  outline: none;
  transition: all ease-out 200ms;
  ::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
  :focus {
    background: white;
  }
`

export default function CocktailSearch(): JSX.Element {
  const [query, setQuery] = useState('')

  return (
    <SearchBar
      type="search"
      placeholder="Search by name, ingredients, etc."
      onChange={(event) => setQuery(event.target.value)}
      value={query}
    />
  )
}
