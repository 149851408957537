import {Global, css} from '@emotion/react'

const globalStyles = css`
  * {
    box-sizing: border-box;
  }
  html {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
      'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    line-height: 1.4;
    --font-family-serif: 'Roboto Slab', serif;
  }
  body {
    margin: 0;
    background: #f8f9fb;
    color: rgba(0, 0, 0, 0.84);
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  input,
  button {
    font: inherit;
  }
`

export default function AppGlobalStyles(): JSX.Element {
  return <Global styles={globalStyles} />
}
