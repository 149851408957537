import styled from '@emotion/styled'
import {NavLink} from 'react-router-dom'
import {gql} from '@apollo/client'

import {CocktailsResults_cocktail, CocktailsResult_cocktail} from './types'
import {buildCocktailPath} from './paths'

const ResultLink = styled(NavLink)`
  display: block;
  padding: 16px;
  transition: all ease-out 120ms;
  &:not(:last-child) {
    border-bottom: 1px solid #e7e9f1;
  }
  :hover {
    background: hsl(0, 0%, 0%, 0.03);
  }
  :active {
    background: hsl(0, 0%, 0%, 0.08);
  }
  &.active {
    background: white;
    box-shadow: inset 6px 0 #3558c7;
    cursor: default;
  }
`

const ResultName = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
`

// const ResultIngredients = styled.div`
//   font-size: 12px;
//   color: rgba(0, 0, 0, 0.4);
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// `

interface CocktailsResultProps {
  cocktail: CocktailsResult_cocktail
}

function CocktailsResult(props: CocktailsResultProps): JSX.Element {
  const {cocktail} = props
  return (
    <ResultLink to={buildCocktailPath(cocktail.id)}>
      <ResultName>{cocktail.name}</ResultName>
      {/* <ResultIngredients>
        {cocktail.ingredients.map(ingredient => ingredient.name).join(', ')}
      </ResultIngredients> */}
    </ResultLink>
  )
}

CocktailsResult.fragments = {
  cocktail: gql`
    fragment CocktailsResult_cocktail on Cocktail {
      id
      name
    }
  `,
}

const List = styled.div`
  overflow-y: scroll;
`

interface CocktailsResultsProps {
  cocktails: CocktailsResults_cocktail[]
}

export default function CocktailsResults(props: CocktailsResultsProps): JSX.Element {
  const {cocktails} = props

  return (
    <List>
      {cocktails.map((cocktail) => (
        <CocktailsResult key={cocktail.id} cocktail={cocktail} />
      ))}
    </List>
  )
}

CocktailsResults.fragments = {
  cocktail: gql`
    fragment CocktailsResults_cocktail on Cocktail {
      id
      ...CocktailsResult_cocktail
    }
    ${CocktailsResult.fragments.cocktail}
  `,
}
