import {gql} from '@apollo/client'

import {Recipe_recipe} from './types'
import RecipeSource from './RecipeSource'
import RecipeComponents from './RecipeComponents'
import RecipeInstructions from './RecipeInstructions'
import RecipeVolume from './RecipeVolume'

interface RecipeProps {
  recipe: Recipe_recipe
}

export default function Recipe(props: RecipeProps): JSX.Element {
  const {recipe} = props
  return (
    <>
      <RecipeSource recipe={recipe} />
      <RecipeComponents components={recipe.components} />
      <RecipeInstructions recipe={recipe} />
      <RecipeVolume recipe={recipe} />
    </>
  )
}

Recipe.fragments = {
  recipe: gql`
    fragment Recipe_recipe on Recipe {
      components {
        ingredient {
          id
        }
        ...RecipeComponents_component
      }
      ...RecipeInstructions_recipe
      ...RecipeSource_recipe
      ...RecipeVolume_recipe
    }
    ${RecipeComponents.fragments.component}
    ${RecipeInstructions.fragments.recipe}
    ${RecipeSource.fragments.recipe}
    ${RecipeVolume.fragments.recipe}
  `,
}
