import {Switch, Route} from 'react-router-dom'

import PageSource from './PageSource'
import PageSources from './PageSources'
import PageCocktails from './PageCocktails'
import PageIngredient from './PageIngredient'
import PageIngredients from './PageIngredients'
import PageMenu from './PageMenu'

export default function AppRouter(): JSX.Element {
  return (
    <Switch>
      <Route exact path="/sources" component={PageSources} />
      <Route exact path="/sources/:source" component={PageSource} />
      <Route exact path="/cocktails/:cocktail?" component={PageCocktails} />
      <Route exact path="/ingredients" component={PageIngredients} />
      <Route exact path="/ingredients/:ingredient" component={PageIngredient} />
      <Route exact path="/menus/:menu/:mode(spec)?" component={PageMenu} />
    </Switch>
  )
}
