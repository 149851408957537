import {RouteComponentProps} from 'react-router-dom'
import {gql, useQuery} from '@apollo/client'

import IngredientCard from '../IngredientCard'
import {PageIngredientQuery, PageIngredientQueryVariables} from '../types'

const PAGE_INGREDIENT_QUERY = gql`
  query PageIngredientQuery($id: ID!) {
    ingredient(id: $id) {
      ...IngredientCard_ingredient
    }
  }
  ${IngredientCard.fragments.ingredient}
`

type PageIngredientProps = RouteComponentProps<{ingredient: string}>

export default function PageIngredient(props: PageIngredientProps): JSX.Element | null {
  const {match} = props
  const {data, loading} = useQuery<PageIngredientQuery, PageIngredientQueryVariables>(
    PAGE_INGREDIENT_QUERY,
    {variables: {id: match.params.ingredient}}
  )
  if (loading || !data?.ingredient) return null
  return <IngredientCard ingredient={data.ingredient} />
}
