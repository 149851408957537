import {useMemo} from 'react'
import {ApolloClient, ApolloProvider, InMemoryCache, createHttpLink, from} from '@apollo/client'
import {createPersistedQueryLink} from '@apollo/client/link/persisted-queries'
import {sha256} from 'crypto-hash'

const API_URL = process.env.GRAPHQL_API_URL

interface AppApolloProviderProps {
  children: React.ReactNode
}

export default function AppApolloProvider(props: AppApolloProviderProps): JSX.Element | null {
  const {children} = props

  const client = useMemo(() => {
    const persistedQueryLink = createPersistedQueryLink({sha256, useGETForHashedQueries: true})
    const httpLink = createHttpLink({uri: API_URL})
    const cache = new InMemoryCache()
    const link = from([persistedQueryLink, httpLink])
    return new ApolloClient({cache, link, version: process.env.GIT_SHA})
  }, [])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
